import Heart from '$icons/heart.svg';
import React, { memo, useEffect } from 'react';
import { StyledHeaderSmallIconOptionText, StyledIconBadge, StyledSmallIconButton } from '../styled';
import { useTranslation } from '~/shared/utils/translation';
import { useFrame } from '~/shared/utils';
import Link from 'next/link';
import { useWishlist } from '~/features/commerce-api/hooks/wishlist/useWishList';

type Props = { textColor?: string; desktop?: boolean; metaMenu: boolean };
export const FavoriteButton = memo((props: Props) => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();
    const { isNewUser, createGuestUser, items } = useWishlist();
    const { textColor, desktop, metaMenu } = props;

    const count = items?.length ?? 0;

    useEffect(() => {
        if (isNewUser) {
            createGuestUser();
        }
    }, [isNewUser, createGuestUser]);

    const favoritePageUrl = frame?.settings?.pageReferences?.favoritesPage?.url;

    if (!favoritePageUrl) return null;

    return (
        <Link passHref href={favoritePageUrl} legacyBehavior>
            <StyledSmallIconButton desktop={desktop} textColor={textColor}>
                <Heart />{' '}
                <StyledHeaderSmallIconOptionText
                    desktop={desktop}
                    textColor={textColor}
                    metaMenu={metaMenu}
                >
                    {translate('header.favorit')}
                </StyledHeaderSmallIconOptionText>
                {count > 0 ? <StyledIconBadge>{count}</StyledIconBadge> : null}
            </StyledSmallIconButton>
        </Link>
    );
});

FavoriteButton.displayName = 'FavoriteButton';
