import styled from '@emotion/styled';

export const StyledMain = styled.main({
    position: 'relative',
    transition: 'all 0.5s ease-in-out',
    flexGrow: 1,
});

export const SkipLink = styled.a(({ theme }) => ({
    color: theme.colors.white,
    background: theme.colors.black,
    padding: theme.space[1],
    position: 'fixed',
    top: 10,
    left: -1000,
    '&:focus-visible': {
        left: 10,
        boxShadow: theme.shadows.accessibility,
    },
    zIndex: 999,
}));
