import React from 'react';
import { variant } from '~/theme';
import {
    StyledCampaignBanner,
    StyledCampaignBannerTextLink,
    StyledIconButtonBanner,
    StyledTopBanner,
    StyledTopBannerContainer,
    StyledTopBannerText,
} from './styled';
import ChevronRight from '$icons/chevron.svg';
import { CloseBanner } from './components/CloseBanner/CloseBanner';
import {} from './styled';
import FlexContent from '~/shared/components/FlexGrowContent';
import { Flex } from '~/shared/components/Flex';
import { Link } from '~/lib/data-contract';
export type CampaignBannerProps = {
    /**
     * Variant Top/Campaign/Basket of the Banner
     */
    variant?: variant;
    /**
     * Prop to add an onClick Event
     */
    onClick?: () => void;
    /**
     * Prop to pass child Component
     */
    onCloseBanner?: () => void;
    /**
     * Color of the Banner text
     */
    textColor: string;
    /**
     * color of the Banner Background
     */
    backGroundColor: string;
    /**
     * Banner Text
     */
    bannerText?: string;
    /**
     * Prop to add the close botton(only wokrs on Mobile)
     */
    withCloseBanner?: boolean;
    /**
     * Prop to add an onClick Event
     */
    bannerLink?: Link;
    /**
     * Prop to know if is desktop Display
     */
    desktop?: boolean;
    /**
     * Prop to pass child Component
     */
    childComponent?: JSX.Element;
    className?: string;
};
export const Banner = (props: CampaignBannerProps) => {
    const {
        onClick,
        variant,
        withCloseBanner,
        onCloseBanner,
        textColor,
        backGroundColor,
        bannerLink,
        bannerText,
        childComponent,
        className,
    } = props;
    switch (variant) {
        case 'top': {
            return (
                <StyledTopBannerContainer
                    bannerBackGroundColor={backGroundColor}
                    className={className}
                >
                    <StyledTopBanner textColor={textColor}>
                        <StyledTopBannerText onClick={onClick} textColor={textColor}>
                            {bannerText}
                        </StyledTopBannerText>
                    </StyledTopBanner>
                </StyledTopBannerContainer>
            );
        }
        case 'campaign': {
            return (
                <>
                    <StyledCampaignBanner
                        className={className}
                        textColor={textColor}
                        bannerBackGroundColor={backGroundColor}
                    >
                        <FlexContent grow={1}>
                            <Flex justifyContent="center">
                                <StyledCampaignBannerTextLink
                                    href={bannerLink?.url}
                                    target={bannerLink?.target}
                                    title={bannerLink?.title}
                                    textColor={textColor}
                                    withCloseBanner={withCloseBanner}
                                    desktop
                                >
                                    {bannerText}
                                    <StyledIconButtonBanner
                                        tabIndex={-1}
                                        aria-label={bannerLink?.title}
                                        children={<ChevronRight />}
                                    />
                                </StyledCampaignBannerTextLink>
                            </Flex>
                        </FlexContent>
                        {withCloseBanner && (
                            <CloseBanner textColor={textColor} onCloseBanner={onCloseBanner} />
                        )}
                    </StyledCampaignBanner>
                </>
            );
        }
        default: {
            return (
                <>
                    <StyledCampaignBanner
                        className={className}
                        textColor={textColor}
                        bannerBackGroundColor={backGroundColor}
                    >
                        <FlexContent grow={1}>
                            <Flex justifyContent="center" alignItems="center">
                                {childComponent}
                            </Flex>
                        </FlexContent>
                        {withCloseBanner && (
                            <CloseBanner textColor={textColor} onCloseBanner={onCloseBanner} />
                        )}
                    </StyledCampaignBanner>
                </>
            );
        }
    }
};
